// extracted by mini-css-extract-plugin
export var root = "PlasmicHomepage-module--root--34bG1";
export var desktop1 = "PlasmicHomepage-module--desktop1--3lbO2";
export var efecto1 = "PlasmicHomepage-module--efecto1--1Vh6g";
export var textura = "PlasmicHomepage-module--textura--1pb8o";
export var sombra = "PlasmicHomepage-module--sombra--3ite6";
export var footer = "PlasmicHomepage-module--footer--3ugY9";
export var facebook = "PlasmicHomepage-module--facebook--3uEF8";
export var instagram = "PlasmicHomepage-module--instagram--pMJQD";
export var youtube = "PlasmicHomepage-module--youtube--RI2hy";
export var icono = "PlasmicHomepage-module--icono--S7mek";
export var title = "PlasmicHomepage-module--title--1vsV5";
export var menu = "PlasmicHomepage-module--menu--3OtW7";
export var logoDisturbio1 = "PlasmicHomepage-module--logoDisturbio1--3nZHJ";
export var rectangle2 = "PlasmicHomepage-module--rectangle2--3oywm";
export var rectangle3 = "PlasmicHomepage-module--rectangle3--18VIn";
export var rectangle4 = "PlasmicHomepage-module--rectangle4--31biE";